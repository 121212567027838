import './css/app.css';
import logo from './images/pier-one-polymers-logo.svg';
import LogRocket from 'logrocket';


function App() {
  LogRocket.init('comerso/pier-one-polymers');
  return (
    <div className="app">
      <div className="container">
        <div className="fade-in-message">
          <p>Website Under Construction</p>
        </div>
        <div className="fade-in-logo-icon">
          <img src={logo} alt="Pier One Polymers" />
        </div>
        <div className="fade-in-logo-text">
          <h1>Pier One Polymers</h1>
        </div>
        <div className="fade-in-cta">
          <p>Please call customer service 810-326-1456</p>
        </div>
        
        {/* <Optin /> */}
      </div>
    </div>
  );
}

export default App;